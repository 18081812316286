a {
  color: #cda24d;
}
div.containers-table {
  width: 95%;
}

div.containers-table tr {
  height: 35px;
}

div.containers-table div[role='toolbar'] {
  max-height: 45px;
  min-height: auto;
}

/* Show premium icon */
.showPremiumDot::after,
.showPremium::after,
div.containers-table th[data-colindex="12"] span > div > div:first-child::after,
div.containers-table th[data-colindex="14"] span > div > div:first-child::after,
div.flatViewTable th[data-colindex="13"] span > div > div:first-child::after,
div.flatViewTable th[data-colindex="15"] span > div > div:first-child::after,
.iconClass::after
 {
  content: url('/assets/premium-icon.png');
  position: relative;
  right: -2px;
  top: -2px;
}
/* Specific settings for premium icon in columns */
/*
div.containers-table th[data-colindex="9"] span.MuiButton-label::after {
  left:  -6px;
  padding: 1px 4px 0px 4px;
}
*/
/* Specific settings for premium icon in action button */
/*
.MuiGrid-item.showPremium::after {
  left: -6px;
  bottom: 12px;
}
*/
/* Specific settings for premium icon in bulk action button */

.MuiIconButton-root.showPremium::after {
  top: -10px;
}

/* Specific settings for premium icon in action button dropdown */
/*
li.showPremium::after {
  bottom: 9px;
}
*/
/* Specific settings for premium icon in action button */
.showPremiumDot::after {
  content: " ";
  width:  10px;
  height:  10px;
  bottom: 17px;
  left:  -6px;
  display: inline-block;
}

@media print {
  div[role='toolbar'] div:nth-child(2) {
    display: none;
  }

  tfoot {
    display: none !important;
  }
}

/* beta ribbon */
.ribbon-wrapper-3 {
  width: 106px;
  height: 108px;
  overflow: hidden;
  position: absolute;
  top: 58px;
  left: -6px; 
}

.ribbon-wrapper-bulkop {
 top: -6px !important;
}

.ribbon-3 {
  font: bold 15px Sans-Serif;
  line-height: 18px;
  color: #333;
  text-align: center;
  text-transform: uppercase;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  position: relative;
  padding: 7px 0;
  left: -33px;
  top: 26px;
  width: 150px;
  background-color: ORANGE;
  color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.5px; 
  outline: 1px dotted #E5E82C;
  outline-offset: -4px;
}

.ribbon-3:before, .ribbon-3:after {
  content: "";
  border-top: 4px solid DARKORANGE;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  position: absolute;
  bottom: -4px; 
}

.ribbon-3:before {
  left: 0; 
}

.ribbon-3:after {
  right: 0; 
}

/* react google chart transparent bg */
.charts rect {
  fill: none !important;
}

/* react google chart pointer cursor */
div[id^="reactgooglegraph-"] g text {
  cursor: pointer;
}

/* no border radius on datatable */
.no-radius-table > div.MuiPaper-root {
  border-radius: 0 !important;
}
.flatViewTable td.MuiTableCell-root, 
.flatViewTable th.MuiTableCell-root {
  padding: 8px 12px;
}
.no-radius-table .MuiTablePagination-toolbar {
  padding-right: 52px;
}

/* hides pagination arrows */
/*.MuiTablePagination-actions {
 display:none;
}*/

/* different padding */
.drivers-table th:first-child, .drivers-table td:first-child, 
.trains-table th:first-child, .trains-table td:first-child, 
.barges-table th:first-child, .barges-table td:first-child {
  padding-left: 30px;
}

.drivers-table th, .drivers-table td, 
.trains-table th, .trains-table td, 
.barges-table th, .barges-table td {
  padding: 2px;
}

/* blinking dots on 'loading' snackbar */
@keyframes blink {
  50% { color: transparent }
}
.dot { animation: 1s blink infinite }
.dot:nth-child(2) { animation-delay: 250ms }
.dot:nth-child(3) { animation-delay: 500ms }

div.pincode-dialog iframe {
  margin-top: 20px;
  width: 100%;
  border: 0;
  height: 100vh;
}

::-ms-clear {
  display: none;
}

div.browser-support {
  position: absolute;
  width: 100%;
  top: 2rem;
  display: flex;
  justify-content: center;
}

div.browser-support div.MuiChip-root {
  background-color: red;
  font-size: 1rem;
  color: white;
}

.organizations > .MuiSelect-selectMenu {
  height: 5px;
  overflow-y: scroll;
}
/*for action dropdown on flat view table*/
.actionsTabView{
  position:absolute;
   right:24px;
   top:2px;
}
@media screen and (max-width: 870px) {
  .actionsTabView{
    position:relative;
    text-align: right;
    height: 40px;
    right:0;
    padding-right:10px;
    background-color: rgb(221, 221, 221);
  }
}
@media screen and (max-width: 600px) {
  .showSearchText{
    width:95% !important;
  }
  .actionsTabView{
    height: 80px;
  }
}

.capitalize {
  text-transform: capitalize;
}

.flex {
  display: flex;
}

.justify-space-between {
  justify-content: space-between;
}

.w-full {
  width: 100%;
}

.cookie-banner {
  background-color: #000000;
  color: #ffffff;
  width: 400px;
  padding: 1rem;
  position: fixed;
  bottom: 2rem;
  z-index: 101;
  font-weight: 500;
}

.cookie-banner-left {
  left: 1.5rem;
}

.cookie-banner-right {
  right: 1.5rem;
}

.cookie-banner button {
  background: none;
	color: inherit;
	border: none;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  padding-right: 0.5rem;
  text-decoration: underline;
  font-weight: 600;

}

.pl-16{
  padding-left: 16px;
}

.pr-16{
  padding-right: 16px;
}